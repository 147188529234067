import Swiper, { Autoplay, Navigation, Pagination }  from 'swiper';
import 'swiper/swiper-bundle.css'
import './index.scss'
import './swiper_btn'


Swiper.use([Autoplay, Navigation, Pagination])

// let swiper_cars = new Swiper(".section01_cars_container", {
//     slidesPerView: '4',
//     breakpoints: {
//         1200: {
//             slidesPerView: 4,
//         },
//         750: {
//             slidesPerView: 3,
//         },
//         200: {
//             slidesPerView: 2,
//         },
//         // 300: {
//         //     slidesPerView: 1,
//         // },
//     },
//     navigation: {
//         nextEl: ".btn-next_3",
//         prevEl: ".btn-prev_3",
//     },
// })

let swiper_gallery= new Swiper(".swiper_gallery", {
    slidesPerView: '3',
    spaceBetween: '0.83%',
    breakpoints: {
        700: {
            slidesPerView: 3,
        },
        300: {
            slidesPerView: 2,
        },
    },
    navigation: {
        nextEl: ".btn-next_5",
        prevEl: ".btn-prev_5",
    },
});


if (document.documentElement.offsetWidth < 601) {
    let img = document.querySelector('.section02_item')
    document.querySelector('.swiper_gallery_wrapper').append(img)

    swiper_gallery.update()
}


let swiper_partners = new Swiper(".section03_partners", {
    slidesPerView: '4',
    // speed: 1500,
    // autoplay: {
    //     delay: 3500,
    //     disableOnInteraction: false,
    //     pauseOnMouseEnter: true,
    // },
    navigation: {
        nextEl: ".btn-next_4",
        prevEl: ".btn-prev_4",
    },
    spaceBetween: 30,

    // pagination: {
    //     el: ".swiper-pagination",
    //     type: "bullets",
    //     clickable: true,
    // },
});

let swiper_section04 = new Swiper(".section04_swiper", {
    navigation: {
        nextEl: ".btn-next_1",
        prevEl: ".btn-prev_1",
    },
    pagination: {
        el: ".pagination_1",
        type: "progressbar",
    },

});

let swiper_section05 = new Swiper(".section05_swiper", {
    slidesPerView: '2.5',
    navigation: {
        nextEl: ".btn-next_2",
        prevEl: ".btn-prev_2",
    },
    pagination: {
        el: ".pagination_2",
        type: "progressbar",
    },

});