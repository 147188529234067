import './index.scss'

let hamburger = document.querySelector('#hamburger')
let header = document.querySelector('.header')

hamburger.addEventListener('click', () => header.classList.toggle('active'))

document.querySelector('.header_mob_nav_sections').addEventListener('click', (e) => {
    if (e.target.tagName === 'A') {
        header.classList.remove('active')
    }
})

// let redirect_btns = document.querySelectorAll('[data-href]')

// redirect_btns.forEach(btn => {
//     btn.addEventListener('click', () => {
//         window.location = btn.dataset.href
//     })
// })