
const swiper_btn = (id) => {
    return `
    <div class="swiper_btn">
        <div class="swiper-button-prev btn-prev_${id}"></div>
        <div class="swiper-pagination pagination_${id}"></div>
        <div class="swiper-button-next btn-next_${id}"></div>
    </div>
    `;
}

let append_classes = document.querySelectorAll('[data-append]')

if (document.documentElement.offsetWidth > 1000) {
    append_classes.forEach(append_class => {
        let section = document.querySelector(append_class.dataset.append)
        section.querySelector('.section_title > .swiper_btn_container').innerHTML = swiper_btn(append_class.dataset.id)
    })
} 
else {
    append_classes.forEach(append_class => {
        let section = document.querySelector(append_class.dataset.append)
        section.querySelector('.section_img > .swiper_btn_container').innerHTML = swiper_btn(append_class.dataset.id)
    })
}